import React, { useEffect, useState } from "react";
import "../Home/NameInfo.css";
import "./HelperMedia.css"

const Header = ({header, pageNumber}) => {
  const [delay, setDelay] = useState(true);
  const [title ] = useState([...header]);

  useEffect(() => {
    setTimeout(() => {
      setDelay(false);
    }, title.length * 30);
  }, [title.length]);

  return (
    <>
      <h1
        aria-label=" Hi, I’m Jeff, I build things for the web."
        className="nameanim nameMedia" style={{fontSize: "3em"}}
      > <span className="pageNumber pageMedia"> 0{pageNumber}.</span>
        {title &&
          title.map((one, index) =>
            one === "space" ? (
              <span style={{ margin: "0.13em" }} />
            ) : one === "break" ? (
              <br />
            ) : (
              <span key={index}
                className={delay ? "buttonD  bounceIn" : "buttonD "}
                aria-hidden="true"
                style={
                  delay
                    ? {
                        animationDelay: `${index * 0.03}s`,
                      }
                    : {}
                }
              >
                {one === "space" ? " " : `${one}`}
              </span>
            )
          )}
      </h1>
    </>
  );
};

export default Header;
