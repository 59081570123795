import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Container, Row, Col, Fade } from "reactstrap";
import SideNav from "./components/NavBar/SideNav";
import IntroAnimation from "./components/Intro/IntroAnimation";
import HomePage from "./components/Home/HomePage";
import { FaGithub } from "react-icons/fa";
import { GrLinkedin } from "react-icons/gr";
import SkillsComponent from "./components/Skills/SkillsComponent";
import PortfolioMain from "./components/Portfolio/PortfolioMain";
import ContactComponent from "./components/Contact/ContactComponent";

function App() {
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 6600);
  }, []);

  const tagOpen = `<body>`;
  const tagClose = `</body>`;

  return (
    <>
      <Router>
        {/* {  
      <Switch>
        <Route  exact path= "/" component={HomePage} />
      </Switch>
    } */}

        {isLoading ? (
          <IntroAnimation isLoading={isLoading} />
        ) : (
          <Fade>
            <Container
              fluid
              className=" h-100 m0  p-0"
              style={{ overflowX: "hidden", overflowY: "auto" }}
            >
              <Row
                classsName="w-100 mx-0 p-0 h-100"
                style={{ height: "100vh", background: "#0B1930" }}
              >
                <SideNav />

                <Col
                  className="  p-0 col-lg-10 col-md-10 col-sm-9 col-xs-10"
                  style={{ marginLeft: "calc(0% + 93px)" }}
                >
                  <div className="p-0 h-100 " mainDiv>
                    <span className="tagUp"> &nbsp; &nbsp; {tagOpen} </span>
                    <Switch>
                      <Route exact path="/home" component={HomePage} />

                      <Route exact path="/skills" component={SkillsComponent} />

                      <Route
                        exact
                        path="/portfolio"
                        component={PortfolioMain}
                      />
                      <Route
                        exact
                        path="/contact"
                        component={ContactComponent}
                      />

                      <Route exact path="/" component={HomePage} />
                      <Route exact path="*" component={HomePage} />
                    </Switch>

                    <span className="tagDown"> &nbsp; &nbsp; {tagClose} </span>
                  </div>
                  <footer>
                    <Row
                      style={{ bottom: "0", left: "50%", textAlign: "center" }}
                    >
                      <Col>
                        <Container>
                          <span
                            className="pageNumber"
                            style={{ fontSize: "1.5vh" }}
                          >
                            {" "}
                            &copy; Jeff-Francis Ofoaro &nbsp;
                            {new Date().getFullYear()}
                          </span>
                        </Container>
                      </Col>
                    </Row>
                  </footer>
                </Col>
                <Col sm={1}>
                  <div className="sideSocial">
                    <div className="socialContainer">
                      <a
                        className="socialLinks"
                        href="https://github.com/jeffascript"
                        alt="social"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaGithub />
                      </a>
                      <a
                        className="socialLinks"
                        href="https://www.linkedin.com/in/jeff-francis-ofoaro/"
                        alt="social"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <GrLinkedin />
                      </a>
                      <a
                        href="mailto:ofoaro.jeff@gmail.com"
                        className="socialLinks"
                        alt="social"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span class="lnr lnr-envelope"></span>
                      </a>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </Fade>
        )}
      </Router>
    </>
  );
}

export default App;
