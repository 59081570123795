import React, { Component } from "react";
import { NavItem, Col, Row } from "reactstrap";
//import navlogo from "./lib/logo.png";
import { NavLink } from "react-router-dom";
import "./NavBar.css"
import logo from "../../images/logo1.png"


class SideNav extends Component {

  render() {

    return (

      <Col xs="2" sm="2" md="1" lg="1" xl="1" className="p-0 h-100 sideNavCol" style={{zIndex:"200", width:"80px", position:"fixed"}}>
        <div className="sidenavStyle h-100 p-2 overflow-hidden ">

        <div className="text-center m-3" >
           <a href="/"> <img src={logo} alt="logo" style={{width:"2.5em"}}/></a>
          </div>

        
   <Row className=" align-items-center justify-content-start h-100 align-content-center ml-1">
   
   
 
   <nav className="  align-items-center justify-content-center w-100" style={{marginTop: "-20em"}}>
   <div className="h-100 w-100 p-2 sideBarDiv " >
     <NavItem className="navlistStyle">
                      <NavLink className="navlinkStyle " to="/home" activeClassName="chosen">
                     
                      <i className=" lnr lnr-home"></i>
                        
                      </NavLink>
                    </NavItem>


                    <NavItem className="navlistStyle">
                      <NavLink className="navlinkStyle " to="/skills" activeClassName="chosen">
                     
                      <i className=" lnr lnr-cog"></i>
                        
                      </NavLink>
                    </NavItem>


                   
                    <NavItem className="navlistStyle">
                      <NavLink className="navlinkStyle " to="/portfolio" activeClassName="chosen">
                     
                      <i className=" lnr lnr-briefcase"></i> 
                        
                      </NavLink>
                    </NavItem>

                    <NavItem className="navlistStyle">
                      <NavLink className="navlinkStyle " to="/contact" activeClassName="chosen">
                     
                      <i className="lnr lnr-envelope"></i>
                        
                      </NavLink>
                    </NavItem>

              
   </div>
    
  </nav>

  

  
      
          


   </Row>

        </div>
      </Col>
    );
  }
}

export default SideNav;
