import React from "react";
import { Container, Row, Col } from "reactstrap";
import Header from "../Helper/Header";
import appcademixImg from "../../images/appcademix.png";
import LinkedInImg from "../../images/linkedIn.png";
import MidasImg from "../../images/midas.png";
import GifImg from "../../images/gif-app.jpg";

import "./Portfolio.css";
import "../Skills/SkillsMain.css";
import ImageShowCase from "./ImageShowCase";
// import { FaGithub, FaExternalLinkSquareAlt } from "react-icons/fa";

//linkedin mockup
// {`React &nbsp; Redux &nbsp; NodeJs &nbsp; MongoDB &nbsp; Heroku`}

//A demo version of Linkedin’s copy in which you can login / register, create your profile, add a post, chat with other users, react on other user's posts, etc.

const myName = [
  "S",
  "o",
  "m",
  "e",
  "space",
  "o",
  "f",
  "space",
  "M",
  "y",
  "space",
  "P",
  "r",
  "o",
  "j",
  "e",
  "c",
  "t",
  "s",
  ".",
];

const appCademixData = {
  img: appcademixImg,
  title: "Appcademix",
  tech: `React   Redux   NodeJs/Express    MongoDB   Heroku`,
  description:
    "A knowledge sharing application that is modeled after producthunt. A web app for product loving enthusiasts to share and geek about tutorials, news and latest tech buzz.",
  githubFe: "https://github.com/jeffascript/AppcademiX-FE",
  githubBe: "https://github.com/jeffascript/AppcademiX-BE",
  url: "https://appcademix-fe.onrender.com/",
};

const linkedInData = {
  img: LinkedInImg,
  title: "LinkedIn Mockup",
  tech: `React   Redux   NodeJs/Express   MongoDB   Heroku`,
  description:
    "A demo version of Linkedin’s copy in which you can login / register, create your profile, add a post, chat with other users, react on other user's posts, etc.",
  githubFe: "https://github.com/jeffascript/linkedin_mockup_Auth",
  githubBe: "https://github.com/jeffascript/BE_Linkedin_Auth",
  url: "https://linked-in-mockup.herokuapp.com/",
};

const gifData = {
  img: GifImg,
  title: "Save A Gif",
  tech: `React  Redux  NodeJs/Express  Redis  MongoDB  Docker  Heroku`,
  description:
    "An app for searching and bookmarking of gifs! You can search for gifs and bookmark them for later use! Previously searched Gifs are cached, and the url for markdown is also available for easy sharing.",
  githubFe: "https://github.com/jeffascript/giphy-node-react",
  githubBe: "https://github.com/jeffascript/giphy-node-react#readme",
  url: "https://save-a-gif.onrender.com/",
};

const midasData = {
  img: MidasImg,
  title: "Midas Restaurant Berlin",
  tech: `React  Bootstrap `,
  description: "A make-over for a Berlin based Restaurant. ",
  githubFe: "https://github.com/jeffascript/midas-berlin-restaurant",
  url: "https://midas-berlin-restaurant.netlify.app/",
};

const PortfolioMain = () => {
  return (
    <>
      <Container fluid className="mb-3 mt-3" id="portfolio">
        <Row className="h-100 w-100 ">
          <Col>
            <Header header={[...myName]} pageNumber={3} />
            {/* <Row className="ml-0 mt-4 mb-4">
                <div >
                <img src={appcademixImg} class="img-fluid" alt="appcademix"  style={{width: "40%"}}/>
                </div>
            </Row>

            <Row className="ml-0 mt-4">
                <div >
                <img src={LinkedInImg} class="img-fluid" alt="appcademix" style={{width: "40%"}}/>
                </div>
            </Row> */}

            <Row className="mt-5 imgRowMedia1">
              <div className="col-md-5 col-sm-10">
                <ImageShowCase {...gifData} />
              </div>
              <div className="col-lg-1 col-md-1 col-xs-1 col-sm-1"></div>

              <div className="col-md-5 col-sm-10">
                <ImageShowCase {...appCademixData} />
              </div>
              <div className="col-md-5 col-sm-10">
                <ImageShowCase {...midasData} />
              </div>
              <div className="col-lg-1 col-md-1 col-xs-1 col-sm-1"></div>
              <div className="col-md-5 col-sm-10">
                <ImageShowCase {...linkedInData} />
              </div>
            </Row>
            <Row>
              {/* <Col>
              {ImageShowCase(LinkedInImg)}

           </Col> 
           <div className
           ="col-lg-1 col-md-1 col-xs-1 col-sm-1"></div>
          
<Col>
{ImageShowCase(appcademixImg)}
</Col> */}

              <Col>
                <p
                  className="pageNumber"
                  style={{ fontSize: "smaller", textAlign: "start" }}
                >
                  {" "}
                  ... More projects to be updated soonest! <br />
                  Please be aware that rendering of some of the apps could take
                  a while since it was deployed on free hosting at this time.{" "}
                </p>
              </Col>
            </Row>
          </Col>

          {/* <Col>
           
            <Row className="ml-0 mt-4">
                <div style={{marginTop: "27%"}}>
                <img src={LinkedInImg} class="img-fluid" alt="appcademix" />
                </div>
            </Row>


          </Col> */}
        </Row>
      </Container>
    </>
  );
};

export default PortfolioMain;
