import React  from 'react';
import { Fade } from 'reactstrap';
import "./Intro.css"
import { CSSTransition } from 'react-transition-group';
import { withRouter } from "react-router-dom"

const IntroAnimation = ({ isLoading }) => {

    // useEffect (()=>{
    
    //     setTimeout(() => {
    //       history.push("/home")
    //     }, 6600);
    
          
    //   }, [history]);

    return (
<CSSTransition
  in={isLoading}
  timeout={400}
  classNames="list-transition"
  unmountOnExit
  appear
>
        <Fade>

      <div className="container-fluid overflow-hidden p-0 " style={{ height: '100vh'}}>  
         <div className="row h-100 w-100 w-100 mx-0 p-0 h-100"  style={{ height: '100vh', background:"#0B1930"}}>
     
          
<div className="col p-0 h-100">
            
        <div className="wrapper text-center" >
  
  <svg width="425" height="94" viewBox="0 0 1425 444" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="comb1" filter="url(#filter0_d)">
<path id="text2" d="M365.865 397.697C418.163 397.697 444.312 359.182 444.312 282.15V65.2168C444.312 52.9447 441.764 45.2982 436.666 42.2773C427.415 36.9909 410.423 34.3477 385.689 34.3477V3.19531H555.611V34.3477C537.675 34.3477 525.969 36.1413 520.494 39.7285C515.019 43.127 511.526 46.8086 510.016 50.7734C508.505 54.7383 507.75 59.5527 507.75 65.2168L508.033 282.434C508.033 357.01 481.884 404.211 429.586 424.035C412.594 430.454 393.808 433.664 373.229 433.664C334.335 433.664 302.617 423.658 278.072 403.645C253.717 383.632 241.539 358.993 241.539 329.729C241.539 314.058 245.976 301.314 254.85 291.496C263.723 281.49 274.674 276.486 287.701 276.486C300.729 276.486 311.301 280.734 319.42 289.23C327.538 297.727 331.598 307.45 331.598 318.4C331.598 329.162 328.954 338.319 323.668 345.871C318.382 353.423 311.585 358.898 303.277 362.297C307.809 373.247 315.833 381.932 327.35 388.352C339.055 394.582 351.894 397.697 365.865 397.697Z" stroke="white" stroke-width="4"/>
<g id="text1">
<path d="M6.38281 253.5V244.8L147.683 158.7L159.083 177.3L39.9828 247.8L159.383 317.1L147.983 335.1L6.38281 253.5Z" stroke="white" stroke-width="4"/>
<path d="M691.231 326.1C687.631 329.3 683.331 332.3 678.331 335.1C673.531 337.9 668.231 340.3 662.431 342.3C656.631 344.3 650.531 345.8 644.131 346.8C637.931 348 631.631 348.6 625.231 348.6C613.231 348.6 602.631 346.8 593.431 343.2C584.231 339.4 576.531 334.1 570.331 327.3C564.131 320.3 559.431 312 556.231 302.4C553.031 292.8 551.431 282 551.431 270C551.431 257.4 553.131 246.2 556.531 236.4C560.131 226.6 565.231 218.4 571.831 211.8C578.431 205.2 586.431 200.2 595.831 196.8C605.231 193.2 615.731 191.4 627.331 191.4C635.731 191.4 644.031 192.5 652.231 194.7C660.431 196.9 667.631 201.1 673.831 207.3C680.231 213.3 685.131 221.7 688.531 232.5C691.931 243.1 693.131 256.9 692.131 273.9H576.031C576.031 291.9 580.831 305.4 590.431 314.4C600.231 323.2 613.231 327.6 629.431 327.6C634.831 327.6 640.131 327 645.331 325.8C650.731 324.4 655.831 322.8 660.631 321C665.431 319.2 669.631 317.2 673.231 315C677.031 312.8 679.931 310.8 681.931 309L691.231 326.1ZM627.931 211.2C621.331 211.2 615.031 211.9 609.031 213.3C603.231 214.7 598.031 217.1 593.431 220.5C589.031 223.7 585.331 228 582.331 233.4C579.531 238.6 577.731 245.1 576.931 252.9H670.231C669.231 239.7 664.931 229.5 657.331 222.3C649.931 214.9 640.131 211.2 627.931 211.2Z" stroke="white" stroke-width="4"/>
<path d="M738.814 345V324.9H774.514V215.1H738.814V195H774.514V186.6C774.514 167.8 779.414 154.2 789.214 145.8C799.014 137.4 813.014 133.2 831.214 133.2C837.814 133.2 843.714 133.6 848.914 134.4C854.114 135.2 859.714 136.8 865.714 139.2L860.314 158.7C855.114 156.5 850.114 155.1 845.314 154.5C840.514 153.7 836.114 153.3 832.114 153.3C818.914 153.3 809.914 156.6 805.114 163.2C800.314 169.6 797.914 180.2 797.914 195H860.014V215.1H797.914V324.9H860.014V345H738.814Z" stroke="white" stroke-width="4"/>
<path d="M918.697 345V324.9H954.397V215.1H918.697V195H954.397V186.6C954.397 167.8 959.297 154.2 969.097 145.8C978.897 137.4 992.897 133.2 1011.1 133.2C1017.7 133.2 1023.6 133.6 1028.8 134.4C1034 135.2 1039.6 136.8 1045.6 139.2L1040.2 158.7C1035 156.5 1030 155.1 1025.2 154.5C1020.4 153.7 1016 153.3 1012 153.3C998.797 153.3 989.797 156.6 984.997 163.2C980.197 169.6 977.797 180.2 977.797 195H1039.9V215.1H977.797V324.9H1039.9V345H918.697Z" stroke="white" stroke-width="4"/>
<path d="M1207.48 131.4L1226.38 139.8L1116.88 387L1097.98 378.6L1207.48 131.4Z" stroke="white" stroke-width="4"/>
<path d="M1276.96 335.1L1265.56 317.1L1384.96 247.8L1265.86 177.3L1277.26 158.7L1418.56 244.8V253.5L1276.96 335.1Z" stroke="white" stroke-width="4"/>
</g>
<g id="mainJ" filter="url(#filter1_dd)">
<path d="M377.832 397.697C430.13 397.697 456.279 359.182 456.279 282.15V65.2168C456.279 52.9447 453.73 45.2982 448.633 42.2773C439.382 36.9909 422.389 34.3477 397.656 34.3477V3.19531H567.578V34.3477C549.642 34.3477 537.936 36.1413 532.461 39.7285C526.986 43.127 523.493 46.8086 521.982 50.7734C520.472 54.7383 519.717 59.5527 519.717 65.2168L520 282.434C520 357.01 493.851 404.211 441.553 424.035C424.561 430.454 405.775 433.664 385.195 433.664C346.302 433.664 314.583 423.658 290.039 403.645C265.684 383.632 253.506 358.993 253.506 329.729C253.506 314.058 257.943 301.314 266.816 291.496C275.69 281.49 286.641 276.486 299.668 276.486C312.695 276.486 323.268 280.734 331.387 289.23C339.505 297.727 343.564 307.45 343.564 318.4C343.564 329.162 340.921 338.319 335.635 345.871C330.348 353.423 323.551 358.898 315.244 362.297C319.775 373.247 327.799 381.932 339.316 388.352C351.022 394.582 363.861 397.697 377.832 397.697Z" fill="#5AE7C8"/>
</g>
</g>
<defs>
<filter id="filter0_d" x="0.382812" y="1.19531" width="1424.18" height="442.469" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
<filter id="filter1_dd" x="198.506" y="0.195312" width="369.072" height="438.469" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dx="-51" dy="1"/>
<feGaussianBlur stdDeviation="2"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.8 0 0 0 0 0.839216 0 0 0 0 0.964706 0 0 0 1 0"/>
<feBlend mode="luminosity" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dx="-28"/>
<feGaussianBlur stdDeviation="0.5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.0392157 0 0 0 0 0.0980392 0 0 0 0 0.184314 0 0 0 1 0"/>
<feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow" result="shape"/>
</filter>
</defs>
</svg>
</div>
  
</div>
         </div>

</div> 
</Fade>

</CSSTransition> );
};

export default withRouter (IntroAnimation);