import React from "react";
import "../Skills/SkillsMain.css";
import { FaGithub, FaExternalLinkSquareAlt } from "react-icons/fa";
// import { Container, Row, Col } from "reactstrap";

const ImageShowCase = ({
  img,
  title,
  tech,
  description,
  githubFe,
  githubBe,
  url,
}) => {
  return (
    <div className="gridMain">
      <a
        className="mainImg"
        href={url}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={img} class="img-fluid coverImg" alt={img} />
        {/* <div className
                        ="coverImg"></div> */}
      </a>
      <div className="describeImg">
        <p className="pageNumber titleFolioMedia"> {title}</p>

        <div className="describeInner">
          <p>{tech}</p>
        </div>
      </div>

      {githubFe && (
        <a
          className="socialLinks img-links"
          href={githubFe}
          alt="social"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaGithub />
        </a>
      )}
      {githubBe && (
        <a
          className="socialLinks  img-links1"
          href={githubBe}
          alt="social"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaGithub />
        </a>
      )}

      <a
        className="socialLinks  img-links2"
        href={url}
        alt="social"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaExternalLinkSquareAlt />
      </a>

      <p className="smallText link-txt smallTextMedia">{description}</p>
    </div>
  );
};

export default ImageShowCase;
