import React from 'react';
import "../Home/SideImage.css"
import faceImg from "../../images/faceImg.jpg"


const SideImage = () => {
    return (
        <div classname="container1">

<div className="kz-photo">



  
<svg className="kz-photo-line"  width="160" height="160" viewBox="0 0 521 514" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="Group 2">
<path id="collar-line" d="M174 317.5C166.8 335.5 161.667 350 160 355L157.5 380L160 409L167 428L170.5 430.5L183 417.5L195 403.5L208.5 395L211.5 391.5L223 403.5L229.5 409V417.5V428L223 438L216.5 451L208.5 462L201.5 476.5L199 489.5" stroke="black"/>
<path id="collar-line-right" d="M180.5 298L190 321L207 336L230 359L240 367H249L267.5 356.5L279.5 348.5L295 336L311.5 321L325 308.5L339 326L344.5 356.5L349 367L339 393V419L333 438.5L325 436L314 426.5L295 410L279.5 397L261 415L255 422L258.5 438.5L261 455.5V473V492L258.5 502" stroke="black"/>
<path id="circle" d="M520 257C520 115.615 413.986 1 283.213 1C127.351 1 1 115.615 1 257C1 398.385 127.351 513 283.213 513C413.986 513 520 398.385 520 257Z" stroke="black"/>
<path id="line-head" d="M80 360C73.6667 363.333 58.3 371.7 47.5 378.5L61 394.5L72 411.5L85.5 424.5L98.5 435.5L115.5 449L129 462.5L148 474.5L164.5 483L186.5 491L208.5 495.5L234 504H261.5H292L319.5 495.5L346 488.5L375 477L397.5 467.5L422 449L438 435.5L462.5 408.5V402L451.5 394.5L429.5 385.5L408.5 378.5L387.5 371L370 364L354.5 360L346 351L343.5 342L346 332.5L340 321.5L328.5 306V296.5V283L331.5 269.5V260L337 251L340 238L343.5 228H348.5L354.5 221.5L364.5 206.5L370 190V177.5L367 172L354.5 168L358.5 153V138.5V125.5L354.5 104.5L343.5 83L331.5 63.5L315 49.5L295 38L275 31L243.5 27.5L218 34L194 43.5L172 59L164.5 74.5L152.5 97.5L148 115.5V138.5L152.5 158V168H148H144V177.5V190V206.5L148 217.5L156.5 228L161 238L164.5 251L175 265.5L178 277L180.5 291L178 300L172 317.5L164.5 325L148 330L123 342L105 351L80 360Z" stroke="black"/>
</g>
</svg>


<img src= {faceImg}  className="img" alt="sideImg"/>
</div>    

</div>  
    );
};

export default SideImage;