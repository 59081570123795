import React, { useState } from "react";
import { Menu } from "antd";
import { Row, Col } from "reactstrap";
import "antd/dist/antd.css";
import "./SkillsMain.css";

const SkillTable = () => {
  const [keyNumber, setKeyNum] = useState("1");

  console.log(keyNumber);

  const handleClick = (e) => {
    const { key } = e; // keyPath, item, domEvent
    console.log("click ", key);
    setKeyNum(key);
  };

  const frontEnd = (
    <>
      <li className="inner_smalText ">HTML5 &#38; (S)CSS </li>
      <li className="inner_smalText ">Javascript (+Es6) &#38; TypeScript</li>
      <li className="inner_smalText ">
        Bootstrap, AntDesign, Chakra UI, Tailwind, Styled-Component
      </li>
      <li className="inner_smalText ">
        React &#38; Redux, Next.js,React Native, Vue, Mapbox{" "}
      </li>
      <li className="inner_smalText ">
        React Testing Library, Cypress, Jest, Codecept.js &#38; Mocha{" "}
      </li>
    </>
  );

  const backEnd = (
    <>
      <li className="inner_smalText ">NodeJs &#38; Express, Golang </li>
      <li className="inner_smalText ">PostgreSql, MongoDB &#38; Redis</li>
      <li className="inner_smalText ">
        Rest, GraphQL, tRPC &#38; Event-driven Microservices
      </li>
      <li className="inner_smalText ">Docker, Kubernetes</li>
      <li className="inner_smalText ">Azure, Aws, Firebase &#38; Heroku</li>
    </>
  );

  return (
    <>
      <Row>
        <Col>
          <Menu
            onClick={(e) => handleClick(e)}
            style={{ width: 256 }}
            defaultSelectedKeys={["1"]}
            mode="inline"
          >
            <Menu.Item key="1">FrontEnd</Menu.Item>
            <Menu.Item key="2">BackEnd</Menu.Item>
            {/* <Menu.Item key="3">Facebook</Menu.Item>
              <Menu.Item key="4">HS Osnabrück</Menu.Item> */}
          </Menu>
        </Col>
        <Col>{keyNumber === "1" ? frontEnd : backEnd}</Col>
      </Row>
    </>
  );
};

export default SkillTable;
