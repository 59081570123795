import React, { Component } from "react";
import "../Home/NameInfo.css";
import { Row, Button } from "reactstrap";
import Jlogo from "../../images/J-logo.png";
// Hi,
// I’m Jeff,
// I build things for the web.

class NameInfo extends Component {
    state = {
        addDelay: true,
        count: 0,
        myName: [
            "H",
            "i",
            ",",
            "break",
            "I",
            "'",
            "m",
            "space",
            "space",
            "space",
            "space",
            "E",
            "F",
            "F",
            ",",
            "break",
            "I",
            "space",
            "b",
            "u",
            "i",
            "l",
            "d",
            "space",
            "t",
            "h",
            "i",
            "n",
            "g",
            "s",
            "space",
            "f",
            "o",
            "r",
            "space",
            "t",
            "h",
            "e",
            "space",
            "w",
            "e",
            "b",
            ".",
        ],
    };

    componentDidMount = () => {
        setTimeout(() => {
            this.setState({ addDelay: false });
        }, this.state.myName.length * 90);
    };

    render() {
        return (
            <div className="container w-100">
                <h1
                    aria-label=" Hi, I’m Jeff, I build things for the web."
                    className="nameanim"
                >
                    {this.state.myName.map((one, index) =>
                        one === "space" ? (
                            <span style={{ margin: "0.13em" }} />
                        ) : one === "break" ? (
                            <br />
                        ) : (
                            <span
                                key={index}
                                className={
                                    this.state.addDelay
                                        ? "buttonD  bounceIn"
                                        : "buttonD "
                                }
                                aria-hidden="true"
                                style={
                                    this.state.addDelay
                                        ? {
                                              animationDelay: `${
                                                  index * 0.08
                                              }s`,
                                          }
                                        : {}
                                }
                            >
                                {one === "space" ? " " : `${one}`}
                                {/* {one === "space" && style={{margin:"0.23em"}}}  */}
                            </span>
                        )
                    )}
                </h1>
                <img src={Jlogo} alt="" className="j-name" />
                <Row className="ml-0 mt-3">
                    <p className="smallText" style={{ fontSize: "1.45em" }}>
                        I'm a Full-stack Developer based in{" "}
                        <a
                            href="https://www.google.com/maps/place/Berlin/@52.5065133,13.1445545,10z/data=!3m1!4b1!4m5!3m4!1s0x47a84e373f035901:0x42120465b5e3b70!8m2!3d52.5200066!4d13.404954"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="smallTextLink"
                        >
                            Berlin
                        </a>
                        .<br /> I design and build web applications. <br />I
                        'dine' in JS!
                    </p>
                </Row>
                <a href="mailto:ofoaro.jeff@gmail.com">
                    <Button className=" myButton">Get In Touch</Button>{" "}
                </a>
            </div>
        );
    }
}
export default NameInfo;
