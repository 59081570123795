import React from 'react';
import { Container, Fade, Row } from 'reactstrap';
import NameInfo from './NameInfo';
import SideImage from './SideImage';
import "./Home.css"

const HomePage = () => {
    return (
       <>
       <Fade id="home">
        <Container fluid className="mb-3">
        <Row className="h-100 w-100">
            <div className="p-0 col-lg-10 col-md-10 col-sm-12 col-xs-12 bigColHome" >
                <NameInfo />
            </div >


            <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12 smallColHome">
          
            <SideImage />
            </div>
        </Row>
        </Container>
        </Fade>
       </>
    );
};

export default HomePage;