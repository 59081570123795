import React from "react";
import { Container, Row, Col } from "reactstrap";
import Header from "../Helper/Header";
import { DiMongodb, DiPostgresql } from "react-icons/di";
import "./SkillsMain.css";
import SkillTable from "./SkillTable";

const myName = [
  "S",
  "k",
  "i",
  "l",
  "l",
  "s",
  "space",
  "&",
  "space",
  "T",
  "e",
  "c",
  "h",
  "-",
  "S",
  "t",
  "a",
  "c",
  "k",
  ".",
];

const SkillsComponent = () => {
  return (
    <>
      <Container className="mb-3 mt-3">
        <Row className="h-100 w-100">
          <Col>
            <Header header={[...myName]} pageNumber={2} />
            <Row
              className="ml-0 mt-3 rowSkillMedia"
              style={{ marginRight: "6%" }}
            >
              <p className="smallText">
                I am a highly motivated full-stack developer based in Berlin.
                Shortly after my master's program in{" "}
                <a
                  href="https://www.hs-osnabrueck.de/"
                  className="smallTextLink"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Hochschüle Osnabrück
                </a>{" "}
                (University of Applied Sciences), I joined the{" "}
                <a
                  href="https://www.strive.school/"
                  className="smallTextLink"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Strive School
                </a>{" "}
                where I worked on a wide variety of interesting and meaningful
                projects. I have been privileged to have worked in several teams
                while learning and applying design patterns/solution that have
                increased the bottom line of these organisations.
              </p>

              <p className="smallText">
                I build products that provide pixel-perfect, performant
                experiences such as:
                <li className="inner_smalText ">
                  Highly interactive Front end / User Interfaces for web and
                  mobile applications{" "}
                </li>
                <li className="inner_smalText ">
                  Progressive Web Applications ( PWA ) in normal and SPA Stacks
                </li>
                <li className="inner_smalText ">
                  Integration of third party services such as Azure / Firebase/
                  AWS / Digital Ocean
                </li>
                <li className="inner_smalText ">Event-Driven Microservice</li>
                <li className="inner_smalText ">
                  RestFul APIs / GraphQL / tRPC{" "}
                </li>
                <li className="inner_smalText ">
                  Backend Architecture in NodeJs & Golang!
                </li>{" "}
              </p>

              <p className="smallText">
                Here are a few technologies I've been working with recently:
              </p>
            </Row>
            <SkillTable />
          </Col>

          <Col lg={5}>
            <Row className="mr-4 tableRow">
              <Col>
                <div className="software-skills-main-div">
                  <ul className="dev-icons  ">
                    <li className="software-skill-inline " name="html-5">
                      <i class="fab fa-html5"></i>
                    </li>
                    <li className="software-skill-inline" name="css3">
                      <i className="fab fa-css3-alt"></i>
                    </li>
                    <li className="software-skill-inline" name="sass">
                      <i className="fab fa-sass"></i>
                    </li>
                    <li className="software-skill-inline" name="JavaScript">
                      <i className="fab fa-js"></i>
                    </li>
                    <li className="software-skill-inline" name="reactjs">
                      <i className="fab fa-react"></i>
                    </li>
                    <li className="software-skill-inline" name="nodejs">
                      <i className="fab fa-node"></i>
                    </li>
                    <li className="software-skill-inline" name="swift">
                      <i class="fab fa-github"></i>
                    </li>
                    <li className="software-skill-inline" name="npm">
                      <i className="fab fa-npm"></i>
                    </li>
                    <li className="software-skill-inline" name="sql-database">
                      <i className="fas fa-database"></i>
                    </li>
                    <li className="software-skill-inline" name="aws">
                      <i className="fab fa-aws"></i>
                    </li>
                    <li className="software-skill-inline" name="firebase">
                      <i className="fas fa-fire"></i>
                    </li>

                    <li className="software-skill-inline" name="mongo">
                      <DiMongodb />
                    </li>
                    <li className="software-skill-inline" name="mongo">
                      <DiPostgresql />
                    </li>

                    <li className="software-skill-inline" name="docker">
                      <i className="fab fa-docker"></i>
                    </li>

                    {/* <li className="software-skill-inline" name="docker">
                        
                        <span class="iconify" data-icon="mdi-microsoft-azure" data-inline="false"></span></li> */}
                  </ul>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SkillsComponent;
