import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import Header from "../Helper/Header";
// import { DiMongodb, DiPostgresql } from "react-icons/di";
import "../Skills/SkillsMain.css";

const myName = [
  "C",
  "o",
  "n",
  "t",
  "a",
  "c",
  "t",
  "space",

  "M",
  "e",
  "space",
  "N",
  "o",
  "w",
  ".",
];
const ContactComponent = () => {
  return (
    <>
      <Container className="mb-3 mt-3">
        <Row className="h-100 w-100">
          <Col>
            <Header header={[...myName]} pageNumber={4} />
            <Row className="ml-0 mt-5">
              <p className="smallText">
                I'm currently looking for new opportunities.
                {/* 
                    I am a highly motivated full-stack developer based in Berlin. Shortly after my master's program in <a href="https://www.hs-osnabrueck.de/" className="smallTextLink">Hochschule Osnbrück</a> ( University of Applied Sciences, Osnabrück), I joined the <a href="https://www.strive.school/" className="smallTextLink">Strive School</a> where I worked on a wide variety of interesting and meaningful projects. */}
              </p>

              {/* 
                    <p className="smallText">
I build products that provide pixel-perfect, performant experiences such as:


<li className="inner_smalText ">
Highly interactive Front end / User Interfaces for web and mobile applications </li>

<li className="inner_smalText ">Progressive Web Applications ( PWA ) in normal and SPA Stacks</li> 

<li className="inner_smalText ">Integration of third party services such as Firebase/ AWS / Digital Ocean</li> 

<li className="inner_smalText ">RestFul API </li>

<li className="inner_smalText ">Backend Architecture in NodeJs!</li>  </p> 

<p className="smallText">
Here are a few technologies I've been working with recently:

</p>  */}
            </Row>

            <Row style={{ margin: "0 0 20%  0" }}>
              {" "}
              <Col>
                <a href="mailto:ofoaro.jeff@gmail.com">
                  <Button className=" myButton">Get In Touch</Button>{" "}
                </a>{" "}
              </Col>{" "}
            </Row>

            {/* Typing interface  starts here */}
            <Col>
              <div className="pageNumber">
                <p className="smallText"> How fast Can you type? </p>
                <a
                  href="http://jeff-ofoaro-type-helper.netlify.app"
                  className="smallTextLink"
                >
                  {" "}
                  Play Game
                </a>{" "}
                <span className="smallText"> To verify</span>
              </div>
            </Col>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ContactComponent;
